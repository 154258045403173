import React from 'react';
import { graphql, Link } from 'gatsby';
import P from 'prop-types';

import Layout from '../layouts/index';

const Blogg = ({ data }) => {
  const {
    title, author, date,
  } = data.markdownRemark.frontmatter;
  const { name, avatar, key } = author;
  const { html } = data.markdownRemark;

  return (
    <Layout bodyClass="page-blogg">
      <div className="intro">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 pb-6">
              <h1>{' '}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mb-4">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div className="team card-two">
                <div className="card-header" style={{ width: '100%' }}>
                  <div className="card-header-left">
                    {avatar && (
                      <div className="card-image">
                        <Link to={`/konsulter/${key}`}>
                          <img
                            alt={name}
                            className="img-fluid mb-2"
                            src={avatar}
                            style={{ width: '100%' }}
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="card-right" style={{ width: '100%', height: '100px' }}>
                    <ul className="card-meta d-flex flex-column justify-content-around" style={{ height: '100px' }}>
                      <li className="resize-on-mobile"><h4 className="mb-0"><strong>{title}</strong></h4></li>
                      <li className="resize-on-mobile">
                        <figure className="m-0">
                          <figcaption className="blockquote-footer">
                            <strong>{`${name}, `}</strong>
                            <cite>{`${date}`}</cite>
                          </figcaption>
                        </figure>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="content" style={{ fontSize: '1.125rem' }} dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY")
        author {
          name
          avatar
          key
        }
      }
      html
    }
  }
`;

Blogg.propTypes = {
  data: P.shape({
    markdownRemark: P.shape({}).isRequired,
  }).isRequired,
};

export default Blogg;
